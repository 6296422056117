export const useBroadcastingOptions = () => {
  return {
    // Endpoint for user authorization
    authorizationEndpoint: import.meta.env.VITE_BACKEND_URL + '/broadcasting/auth',
    // Broadcaster
    broadcaster: 'pusher',
    // App key
    appKey: import.meta.env.VITE_PUSHER_APP_KEY,
    // Websocket Host
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    // Websocket Port
    wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    // Secure Websocket Port
    wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    // Force to use TLS
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    // App Cluster
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'eu',
    // Transport protocols
    enabledTransports: ['ws', 'wss'],
  };
};
