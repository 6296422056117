import en from './en.json';
import de from './de.json';
import de_INF from './de_INF.json';

import en_banner from '@/modules/banner/assets/lang/en.json';
import de_banner from '@/modules/banner/assets/lang/de.json';
import de_INF_banner from '@/modules/banner/assets/lang/de_INF.json';

import en_chat from '@/modules/chat/assets/lang/en.json';
import de_chat from '@/modules/chat/assets/lang/de.json';
import de_INF_chat from '@/modules/chat/assets/lang/de_INF.json';

import en_cookies from '@/modules/cookies/assets/lang/en.json';
import de_cookies from '@/modules/cookies/assets/lang/de.json';
import de_INF_cookies from '@/modules/cookies/assets/lang/de_INF.json';

import en_feedback from '@/modules/feedback/assets/lang/en.json';
import de_feedback from '@/modules/feedback/assets/lang/de.json';
import de_INF_feedback from '@/modules/feedback/assets/lang/de_INF.json';

import en_interaction from '@/modules/interaction/assets/lang/en.json';
import de_interaction from '@/modules/interaction/assets/lang/de.json';
import de_INF_interaction from '@/modules/interaction/assets/lang/de_INF.json';

import en_link from '@/modules/link/assets/lang/en.json';
import de_link from '@/modules/link/assets/lang/de.json';
import de_INF_link from '@/modules/link/assets/lang/de_INF.json';

import en_login from '@/modules/login/assets/lang/en.json';
import de_login from '@/modules/login/assets/lang/de.json';
import de_INF_login from '@/modules/login/assets/lang/de_INF.json';

import en_modpanel from '@/modules/modpanel/assets/lang/en.json';
import de_modpanel from '@/modules/modpanel/assets/lang/de.json';
import de_INF_modpanel from '@/modules/modpanel/assets/lang/de_INF.json';

import en_notes from '@/modules/notes/assets/lang/en.json';
import de_notes from '@/modules/notes/assets/lang/de.json';
import de_INF_notes from '@/modules/notes/assets/lang/de_INF.json';

import en_player from '@/modules/player/assets/lang/en.json';
import de_player from '@/modules/player/assets/lang/de.json';
import de_INF_player from '@/modules/player/assets/lang/de_INF.json';

import en_presenter from '@/modules/presenter/assets/lang/en.json';
import de_presenter from '@/modules/presenter/assets/lang/de.json';
import de_INF_presenter from '@/modules/presenter/assets/lang/de_INF.json';

import en_questions from '@/modules/questions/assets/lang/en.json';
import de_questions from '@/modules/questions/assets/lang/de.json';
import de_INF_questions from '@/modules/questions/assets/lang/de_INF.json';

import en_users from '@/modules/users/assets/lang/en.json';
import de_users from '@/modules/users/assets/lang/de.json';
import de_INF_users from '@/modules/users/assets/lang/de_INF.json';

import en_user_tags from '@/modules/tags/assets/lang/en.json';
import de_user_tags from '@/modules/tags/assets/lang/de.json';
import de_INF_user_tags from '@/modules/tags/assets/lang/de_INF.json';


import en_audit from '@/modules/audit/assets/lang/en.json';
import de_audit from '@/modules/audit/assets/lang/de.json';
import de_INF_audit from '@/modules/audit/assets/lang/de_INF.json';

import en_live from '@/modules/live/assets/lang/en.json';
import de_live from '@/modules/live/assets/lang/de.json';
import de_INF_live from '@/modules/live/assets/lang/de_INF.json';

import en_support from '@/modules/support/assets/lang/en.json';
import de_support from '@/modules/support/assets/lang/de.json';
import de_INF_support from '@/modules/support/assets/lang/de_INF.json';

import en_actions from '@/modules/actions/assets/lang/en.json';
import de_actions from '@/modules/actions/assets/lang/de.json';
import de_INF_actions from '@/modules/actions/assets/lang/de_INF.json';

import { mergeDeep } from '@/helper/merge';

export const languages = {
  en: mergeDeep(
    en,
    en_banner,
    en_chat,
    en_cookies,
    en_feedback,
    en_interaction,
    en_link,
    en_login,
    en_modpanel,
    en_notes,
    en_player,
    en_presenter,
    en_questions,
    en_users,
    en_user_tags,
    en_audit,
    en_live,
    en_support,
    en_actions
  ),
  de: mergeDeep(
    de,
    de_banner,
    de_chat,
    de_cookies,
    de_feedback,
    de_interaction,
    de_link,
    de_login,
    de_modpanel,
    de_notes,
    de_player,
    de_presenter,
    de_questions,
    de_users,
    de_user_tags,
    de_audit,
    de_live,
    de_support,
    de_actions
  ),
  de_INF: mergeDeep(
    de_INF,
    de_INF_banner,
    de_INF_chat,
    de_INF_cookies,
    de_INF_feedback,
    de_INF_interaction,
    de_INF_link,
    de_INF_login,
    de_INF_modpanel,
    de_INF_notes,
    de_INF_player,
    de_INF_presenter,
    de_INF_questions,
    de_INF_users,
    de_INF_user_tags,
    de_INF_audit,
    de_INF_live,
    de_INF_support,
    de_INF_actions
  ),
};
