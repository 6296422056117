import { isTruish } from '@/helper/what';

export const useFeedbackOptions = () => {
  return {
    enabled: isTruish(import.meta.env.VITE_FEEDBACK_ENABLED),
    moderation: isTruish(import.meta.env.VITE_FEEDBACK_MODERATION),
    // Minimum value (only tested for 1)
    minValue: 1,
    // Maximum value (only tested for 5)
    maxValue: 5,
    defaults: {
      // What to show if no star was selected
      rating: '/',
      // What to show if no message was sent
      message: '/',
    },
    rating: {
      // Labels of the ratings in the chart
      labels: ['1', '2', '3', '4', '5'],
      // Colors associated with the labels in the chart
      colors: ['orangered', 'orange', 'gold', 'palegreen', 'lawngreen'],
    },
  };
};
