{
  "success": "Successful",
  "errors": {
    "catchall": "Something went wrong. Please reload the page or try again later.",
    "missingCode": "The code given is not correct."
  },
  "pages": {
    "about": "About",
    "privacy": "Privacy",
    "main": "Main View"
  },
  "defaults": {
    "activate": "Activate",
    "deactivate": "Deactivate"
  },
  "modal": {
    "ok": "Confirm",
    "cancel": "Cancel",
    "close": "Close",
    "enterCode": "Enter Code",
    "views": {
      "stop-warning": "Select the views you want to stop:",
      "stop-all": "Stop All",
      "stop-selected": "Stop Views Individually"
    }
  },
  "dashboard": {
    "select-module": "Select Module",
    "copied": "Copied to the clipboard",
    "remove": "Remove"
  }
}
