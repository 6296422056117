import { isTruish } from '@/helper/what';

export const useLoginOptions = () => {
  return {
    // Whether to show a login page if not logged in
    enabled: isTruish(import.meta.env.VITE_LOGIN_ENABLED),
    // Enable login using magic links
    magic: isTruish(import.meta.env.VITE_ENABLE_MAGIC_LOGIN),
    // Enable login without mail
    mailless: isTruish(import.meta.env.VITE_ENABLE_MAILLESS_LOGIN),
    // Default interface shown in the login, either direct, mailless, or magic
    defaultMode: import.meta.env.VITE_DEFAULT_LOGIN_MODE,
    // Which route to push the user after redirect
    redirectRoute: '/',
    // Available user roles
    roles: {
      admin: 'admin',
      stage: 'stage',
      moderation: 'moderator',
      live: 'live',
      presenter: 'presenter',
      none: 'none',
    },
    // JWT login
    tokenLogin: {
      // Pass jwt token to instance
      // Can be enabled even if the general login is disabled
      enabled: isTruish(import.meta.env.VITE_JWT_ENABLED),
      // Query parameter where the token is given
      queryParameter: 'token',
      // Header where the token is stored
      // This is the easiest option to add it to every request
      header: 'Authorization',
    },
    // QR Code Login
    qrLogin: {
      // Show pages for qr code generation and login forwarding
      enabled: isTruish(import.meta.env.VITE_QR_ENABLED),
    },
    autoReload: {
      enabled: true,
      interval: 60000,
    },
  };
};
