import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { useAxiosOptions } from '@/options/axios';
import { useBroadcastingOptions } from '@/options/broadcasting';

export function createEcho() {
  let axiosOptions = useAxiosOptions();
  let options = useBroadcastingOptions();

  Pusher.Runtime.createXHR = () => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = axiosOptions.withCredentials;
    return xhr;
  };
  window.Pusher = Pusher;

  window.Echo = new Echo({
    broadcaster: options.broadcaster,
    key: options.appKey,
    wsHost: options.wsHost,
    wsPort: options.wsPort,
    wssPort: options.wssPort,
    forceTLS: options.forceTLS,
    cluster: options.cluster,
    enabledTransports: options.enabledTransports,
    channelAuthorization: {
      endpoint: options.authorizationEndpoint,
    },
  });
}
