import { isTruish } from '@/helper/what';

const getTotalWeight = (words) => {
  console.debug(words);
  return words.map(e => e[1]).reduce((a, b) => a + b, 0);
}
const getWordWeight = (words, word) => {
  return words.filter(e => e[0] === word)[0][1];
}

const isMaxWord = (words, word) => {
  return words.filter(e => e[1] > getWordWeight(words, word)).length === 0;
}

const random = (list) => {
  return list[Math.floor(Math.random() * list.length)];
}

export const useInteractionOptions = () => {
  return {
    enabled: isTruish(import.meta.env.VITE_INTERACTION_ENABLED),
    moderation: isTruish(import.meta.env.VITE_INTERACTION_MODERATION),
    wordcloud: {
      // https://github.com/SeregPie/VueWordCloud
      colors: (words, word, weight) => (isMaxWord(words, word) ? 'rgb(255, 197, 0)' : getTotalWeight(words) * 0.1 <= weight ? '#76868b' : '#717d80'),
      font: 'Roboto',
      animationDuration: 500,
      animationEasing: 'ease',
      animationOverlap: 1,
      spacing: 1,
      fontSizeRatio: 0,
      rotationUnit: 'turn',
      rotation: (words, word, weight) => (isMaxWord(words, word) ? 0 : random([0, 3/4])),
    },
    vote: {
      shapeColors: ['#5F9EA0', '#A0522D', '#7B68EE', '#8FBC8F', '#708090'],
      chart: {
        // https://www.chartjs.org/docs/latest/configuration/
        animation: {
          duration: 500,
        },
        scales: {
          y: {
            suggestedMax: 10,
            ticks: {
              stepSize: 1,
            },
            color: '#76868b',
            grid: {
              display: false,
            },
          },
          x: {
            color: '#76868b',
            grid: {
              display: false,
            },
          }
        },
        plugins: {
          legend: {
            labels: {
              color: '#76868b',
              font: {
                family: '"Open Sans", sans-serif',
                size: 30
              },
            }
          }
        }
      },
    },
  };
};
