export function isSet(object) {
  return !isNotSet(object);
}

export function isNotSet(object) {
  return isUndefined(object) || isNull(object);
}

export function isEmpty(object) {
  return (
    isNotSet(object) ||
    (isArray(object) && object.length === 0) ||
    (isString(object) && object === '') ||
    (isNumber(object) && object === 0) ||
    (isObject(object) && Object.keys(object).length === 0)
  );
}

export function isNotEmpty(object) {
  return !isEmpty(object);
}

export function isFunction(object) {
  return typeof object === 'function';
}

export function isArray(object) {
  return Array.isArray(object);
}

export function isObject(object) {
  return typeof object === 'object' && !isArray(object);
}

export function isString(object) {
  return typeof object === 'string';
}

export function isNumber(object) {
  return typeof object === 'number';
}

export function isUndefined(object) {
  return typeof object === 'undefined';
}

export function isNull(object) {
  return object === null;
}

export function contains(arrayOrObject, value) {
  return (
    (isArray(arrayOrObject) && arrayOrObject.indexOf(value) !== -1) ||
    (isObject(arrayOrObject) && value in arrayOrObject)
  );
}

export function isTruish(object) {
  return (
    object === 'true' ||
    object === 1 ||
    object === '1' ||
    object === 't' ||
    object === 'on' ||
    object === true
  );
}

export function isFalsish(object) {
  return (
    object === 'false' ||
    object === 0 ||
    object === -1 ||
    object === '0' ||
    object === '-1' ||
    object === 'f' ||
    object === 'off' ||
    object === false
  );
}

