export const useAxiosOptions = () => {
  return {
    // Enable credentials for axios (unless you know what you are doing keep this always true)
    withCredentials: true,
    withXSRFToken: true,
    // The public URL to the backend server
    backendUrl: import.meta.env.VITE_BACKEND_URL,
    // Name of the xsrf cookie
    xsrfCookieName: 'XSRF-TOKEN',
  };
};
