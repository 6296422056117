{
  "success": "Erfolgreich",
  "errors": {
    "catchall": "Etwas ist schief gelaufen. Bitte lade die Seite neu oder probiere es später erneut.",
    "missingCode": "Der eingegebene Code ist nicht korrekt."
  },
  "pages": {
    "about": "Impressum",
    "privacy": "Datenschutz",
    "main": "Hauptansicht"
  },
  "defaults": {
    "activate": "Aktivieren",
    "deactivate": "Deaktivieren"
  },
  "modal": {
    "ok": "Bestätigen",
    "cancel": "Abbrechen",
    "close": "Schließen",
    "enterCode": "Code eingeben",
    "views": {
      "stop-warning": "Wähle die Anzeigen aus, welche beendet werden sollen:",
      "stop-all": "Alle anhalten",
      "stop-selected": "Ansichten einzeln auswählen"
    }
  },
  "dashboard": {
    "select-module": "Modul auswählen",
    "copied": "Wurde in die Zwischenablage kopiert",
    "remove": "Entfernen"
  }
}
