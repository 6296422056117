import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import { languages } from '@/assets/lang';
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import { createGuardedRouter } from './router';
import { createEcho } from '@/helper/echo';

let localizationOptions = useLocalizationOptions();

const app = createApp(App);

let locale = localStorage.getItem('locale') ?? localizationOptions.locale;

window.i18n = createI18n({
  locale: locale,
  fallbackLocale: localizationOptions.fallbackLocale,
  legacy: false,
  messages: languages,
});

app.use(window.i18n);

let axiosOptions = useAxiosOptions();
app.use(VueAxios, axios);
window.axios = app.config.globalProperties.axios;
app.provide('axios', window.axios); // provide 'axios'
app.config.globalProperties.axios.defaults.withCredentials = axiosOptions.withCredentials;
app.config.globalProperties.axios.defaults.withXSRFToken = axiosOptions.withXSRFToken;
app.config.globalProperties.axios.defaults.baseURL = axiosOptions.backendUrl;
app.config.globalProperties.axios.defaults.xsrfCookieName = axiosOptions.xsrfCookieName;
app.config.globalProperties.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

app.use(createPinia());

window.router = createGuardedRouter();
app.use(window.router);

app.config.errorHandler = err => {
  let appOptions = useAppOptions();
  if (appOptions.logErrors) {
    console.error(err);
  }
};

let sentryOptions = useSentryOptions();
if (sentryOptions.enabled && localStorage.getItem(sentryOptions.consentCookieName) !== null) {
  Sentry.init({
    app,
    dsn: sentryOptions.dsn,
    debug: sentryOptions.debug,
    environment: sentryOptions.environment,
    release: sentryOptions.release,
    ignoreErrors: sentryOptions.ignoreErrors,
    ignoreTransactions: sentryOptions.ignoreTransactions,
    autoSessionTracking: sentryOptions.autoSessionTracking,
    integrations: sentryOptions.integrations,
    sampleRate: sentryOptions.sampleRate,
    tracesSampleRate: sentryOptions.tracesSampleRate,
    replaysSessionSampleRate: sentryOptions.replaysSessionSampleRate,
    replaysOnErrorSampleRate: sentryOptions.replaysOnErrorSampleRate,
  });
}

app.mount('#app');

createEcho();

export function getSimpleHeader() {
  let loginOptions = useLoginOptions();

  let axiosOptions = {
    headers: {
      'Accept-Language': window.i18n.global.locale.value,
    },
  };

  if (hasToken()) {
    axiosOptions['headers'][loginOptions.tokenLogin.header] = getToken();
  }
  return axiosOptions;
}

export function getFormHeader() {
  let axiosOptions = getSimpleHeader();
  axiosOptions['headers']['Content-Type'] = 'multipart/form-data';
  return axiosOptions;
}

import '@/assets/sass/base.sass';
import { useLoginOptions } from '@/modules/login/plugins/options';
import { useLocalizationOptions } from '@/options/localization';
import { useAxiosOptions } from '@/options/axios';
import { useAppOptions } from '@/options/app';
import { useSentryOptions } from '@/options/sentry';
import { getToken, hasToken } from '@/helper/token';
