// import * as Sentry from '@sentry/vue';
// import { CaptureConsole, ExtraErrorData, HttpClient, Debug } from '@sentry/integrations';
import { CaptureConsole, ExtraErrorData, HttpClient } from '@@/@sentry/integrations';
import { isTruish } from '@/helper/what';

export const useSentryOptions = () => {
  return {
    enabled: isTruish(import.meta.env.VITE_SENTRY_ENABLED),
    // Name of the cookie set before activating sentry
    consentCookieName: 'sentryConsent',
    // Address where to send errors
    dsn: import.meta.env.VITE_SENTRY_DSN,
    // Debug mode if sending to the server went wrong
    debug: false,
    // Environment to show the error in
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    // Release version
    release: import.meta.env.VITE_SENTRY_DEVELOPMENT,
    // Ignore errors based on this regex
    ignoreErrors: null,
    // Ignore transactions based on this regex
    ignoreTransactions: null,
    // Send session events to Sentry
    autoSessionTracking: true,
    // Percentage of errors actually captured
    sampleRate: 10,
    // Percentage of traces actually captured
    tracesSampleRate: 1.0,
    // Replays stored if no errors occurred
    replaysSessionSampleRate: 0.0,
    // Replays stored if error occurred
    replaysOnErrorSampleRate: 1.0,
    // Integrations of sentry in vue
    integrations: [
      // Capture performance data for the Browser.
      //new Sentry.BrowserTracing({
      //    routingInstrumentation: Sentry.vueRouterInstrumentation(router), // Henne Ei mitm router
      //}),
      // Capture a video-like reproduction of what was happening in the user's browser.
      // Do not use in production!
      // Requires CSP https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/
      // Possible issues with GlitchTip?
      // new Sentry.Replay(),
      // Captures all Console API calls via `captureException` or `captureMessage`.
      new CaptureConsole({
        levels: ['warn', 'error'],
      }),
      // Extracts all non-native attributes from the error object and attaches them to the event as extra data.
      new ExtraErrorData(),
      // Captures errors on failed requests from Fetch and XHR and attaches request and response information.
      new HttpClient(),
      // Allows you to inspect the contents of a processed event and hint object that gets passed to beforeSend or beforeSendTransaction.
      // new Debug(),
    ],
  };
};
