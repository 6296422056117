import { isTruish } from '@/helper/what';

export const useAppOptions = () => {
  return {
    baseUrl: import.meta.env.BASE_URL,
    // Log errors to the user
    // This does not affect external logging nor request error messages
    logErrors: isTruish(import.meta.env.VITE_LOG_ERRORS),
    // Logs originating functions from the backend to the user on errors
    logErrorSource: isTruish(import.meta.env.VITE_LOG_ERROR_SOURCE),
    // Certain actions like bulk deletion require a code
    // This is not for security, only against accidental deletion (it is stored in plain text anyway)
    // Unset it to disable it
    lockCode: import.meta.env.VITE_LOCK_CODE,
    // Show additional information when deleting or updating objects
    showObjectInformation: true
  };
};
