import { isTruish } from '@/helper/what';

export const useChatOptions = () => {
  return {
    enabled: isTruish(import.meta.env.VITE_CHAT_ENABLED),
    moderation: isTruish(import.meta.env.VITE_CHAT_MODERATION),
    // Load the chat history on page load
    history: isTruish(import.meta.env.VITE_CHAT_HISTORY),
    // Maximum number of messages until oldest messages are dropped
    maxMessages: 200,
    // Maximum number of messages loaded after page load, set to -1 to disable
    maxMessagesLoaded: 40,
    // Pixels until scroll is paused
    scrollSoftBuffer: 40,
  };
};
