import { useLoginOptions } from '@/modules/login/plugins/options';

export function hasToken() {
  let loginOptions = useLoginOptions();

  if (!loginOptions.tokenLogin.enabled) {
    return false;
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if (urlParams.has(loginOptions.tokenLogin.queryParameter)) {
    return true;
  }
}

export function getToken() {
  if (!hasToken()) return null;

  let loginOptions = useLoginOptions();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(loginOptions.tokenLogin.queryParameter);
}
