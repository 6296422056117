import { useToast } from 'vue-toast-notification';
import { useAppOptions } from '@/options/app';

export function handleError(error, showToast = true) {
  let _err;

  let options = useAppOptions();

  if (options.logErrors) {
    console.error(error);
  }
  try {
    if (typeof error === 'object' && 'request' in error) {
      let response = JSON.parse(error.request.response);
      _err = response.message;
      if (options.logErrorSource) {
        if ('file' in response) {
          _err = _err + '\n' + response.file;
        }
        if ('line' in response) {
          _err = _err + ':' + response.line;
        }
      }
    } else if (typeof error === 'object' && 'error' in error) {
      _err = error.error;
    } else {
      if (options.logErrors) {
        _err = error.message
      }
    }
  } finally {
    const { t } = window.i18n.global;
    const toast = useToast();
    if (_err === '') {
      _err = t('errors.catchall');
    }
    if (!showToast) {
      console.error(_err ?? t('errors.catchall'));
    } else {
      toast.error(_err ?? t('errors.catchall'), {
        duration: 7000,
      });
    }
  }
}
