import { computed, ref, toValue } from 'vue';
import { defineStore } from 'pinia';
import { handleError } from '@/helper/error';
import { useLoginOptions } from '@/modules/login/plugins/options';
import { getSimpleHeader } from '@/main';
import { isSet } from '@/helper/what';

export const useAuthStore = defineStore('auth', () => {
  let loginOptions = useLoginOptions();

  let userInfo = ref(null);
  let authenticated = computed(() => isUserInfoSet());

  const isAdmin = ref(false);
  const isStage = ref(false);
  const isModeration = ref(false);
  const isLiveUser = ref(false);
  const isPresenter = ref(false);
  const isNone = ref(false);

  const name = ref('');
  const email = ref('');
  const userId = ref(-1);
  const provider = ref('');
  const pid = ref('');
  const watermark = ref('');

  const qrCode = ref('');

  function isUserInfoSet() {
    return userInfo.value !== null && userInfo.value !== '';
  }

  async function loadUser() {
    try {
      let options = getSimpleHeader();

      await window.axios.get('/sanctum/csrf-cookie', options);
      let response = await window.axios.get('/api/user', options);
      return await extractUserData(response);
    } catch (err) {
      handleError(err);
      await removeData();
      return true;
    }
  }

  async function removeData() {
    userInfo.value = null;
    isAdmin.value = null;
    isStage.value = null;
    isModeration.value = null;
    isLiveUser.value = null;
    isPresenter.value = null;
    isNone.value = null;
    name.value = '';
    email.value = '';
    userId.value = -1;
    provider.value = '';
    pid.value = '';
    watermark.value = '';
  }

  function setIfNew(variable, value, callback = null) {
    if (toValue(variable) === value) return false;
    variable.value = value;
    if (isSet(callback)) {
      callback();
    }
    return true;
  }

  async function extractUserData(payload) {
    let dirty = false;

    userInfo.value = payload.data;

    setIfNew(
      isAdmin,
      payload.data.roles?.some(role => role.name === loginOptions.roles.admin)
    );
    setIfNew(
      isStage,
      payload.data.roles?.some(role => role.name === loginOptions.roles.stage)
    );
    setIfNew(
      isModeration,
      payload.data.roles?.some(role => role.name === loginOptions.roles.moderation)
    );
    setIfNew(
      isLiveUser,
      payload.data.roles?.some(role => role.name === loginOptions.roles.live)
    );
    setIfNew(
      isPresenter,
      payload.data.roles?.some(role => role.name === loginOptions.roles.presenter)
    );
    setIfNew(
      isNone,
      payload.data.roles?.some(role => role.name === loginOptions.roles.none)
    );

    setIfNew(name, payload.data.name);
    setIfNew(email, payload.data.email);
    dirty = setIfNew(userId, payload.data.id) || dirty;

    setIfNew(provider, payload.data.remote_provider);
    setIfNew(pid, payload.data.pid);
    setIfNew(watermark, payload.data.watermark);

    return dirty;
  }

  async function login(data, route = '', callback = null) {
    try {
      let options = getSimpleHeader();
      let response = await window.axios.post('/api/login' + route, data, options);
      await extractUserData(response);
      if (callback) {
        callback();
      }
      return isUserInfoSet();
    } catch (err) {
      handleError(err);
    }
    return false;
  }

  async function passwordLogin(email, password) {
    if (await login({ email: email, password: password })) {
      await window.router.push(loginOptions.redirectRoute);
    }
  }

  async function magicLogin(email, name, callback = null) {
    return await login({ email: email, name: name }, '/magic', callback);
  }

  async function magicToken(userId, hash, token, expires, signature) {
    try {
      let options = getSimpleHeader();
      let response = await window.axios.post(
        '/api/login/magic/' +
        encodeURIComponent(toValue(userId)) + '/' +
        encodeURIComponent(toValue(hash)) + '/' +
        encodeURIComponent(toValue(token)) +
        '?expires=' + encodeURIComponent(expires) +
        '&signature=' + encodeURIComponent(signature),
        options
      );
      await extractUserData(response);
      return isUserInfoSet();
    } catch (err) {
      handleError(err);
    }
    return false;
  }

  async function maillessLogin(name) {
    if (await login({ name: name }, '/mailless')) {
      await window.router.push(loginOptions.redirectRoute);
    }
  }

  async function generateQrCode() {
    try {
      let options = getSimpleHeader();
      console.log(options);
      let response = await window.axios.post('/api/login/qr/generate', {}, options);
      qrCode.value = response.data;
    } catch (err) {
      handleError(err);
    }
  }

  async function qrCodeLogin(token) {
    try {
      let options = getSimpleHeader();
      let response = await window.axios.post('/api/login/qr', { data: token }, options);
      await extractUserData(response);
      return isUserInfoSet();
    } catch (err) {
      handleError(err);
    }
    return false;
  }

  return {
    userInfo,
    authenticated,
    isAdmin,
    isStage,
    isModeration,
    isLiveUser,
    isPresenter,
    isNone,
    passwordLogin,
    magicLogin,
    magicToken,
    maillessLogin,
    loadUser,
    name,
    email,
    userId,
    provider,
    pid,
    watermark,
    qrCode,
    generateQrCode,
    qrCodeLogin,
  };
});
