import { isTruish } from '@/helper/what';

export const usePlayerOptions = () => {
  return {
    // If disabled, only individual submodules may be opened by their respective path
    enabled: true,
    posterUrl: '',
    enableAudio: isTruish(import.meta.env.VITE_AUDIO_STREAM_ENABLED),
    enableAdmin: isTruish(import.meta.env.VITE_ADMIN_STREAM_ENABLED),
    watermark: {
      enabled: isTruish(import.meta.env.VITE_WATERMARK_ENABLED),
      // Show the username in the watermark
      showName: isTruish(import.meta.env.VITE_WATERMARK_SHOW_NAME),
      // Show the users email in the watermark
      showEmail: isTruish(import.meta.env.VITE_WATERMARK_SHOW_MAIL),
      // Show the user identifier
      showPid: isTruish(import.meta.env.VITE_WATERMARK_SHOW_PID),
      // Number of characters taken from the hash
      // https://www.bdayprob.com/
      // e.g. having 9 characters, we have 16^9 birthdays, so we need 11727 people to have a 0.1% for a collision
      pidLength: parseInt(import.meta.env.VITE_WATERMARK_PID_LENGTH),
      // Show an additional (fixed) string in the watermark (string, set to false to hide)
      additionalString: false,
    },
  };
};
