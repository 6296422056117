<script setup>
import { RouterView } from 'vue-router';

import { onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '@/modules/login/stores/auth';
import { useRouter } from '@@/vue-router';
import { useLoginOptions } from '@/modules/login/plugins/options';

let authStore = useAuthStore();
let { loadUser } = authStore;

let options = useLoginOptions();
let router = useRouter();
let intervalId = null;

onMounted(() => {
  if (!options.autoReload.enabled) return;
  intervalId = setInterval(() => {
    loadUser().then(dirty => {
      if (dirty) {
        router.go(0);
      }
    });
  }, options.autoReload.interval);
});

onUnmounted(() => {
  if (intervalId) clearInterval(intervalId);
});
</script>

<template>
  <div id="bg"></div>
  <RouterView />
</template>
